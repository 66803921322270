import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllDataAPI, filterByParams } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import CsvDownloader from 'react-csv-downloader';
import { Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import { numberFormat } from '../components/numberFormat';
import moment from 'moment';
import { FiRefreshCcw } from 'react-icons/fi';

const AllOrder = () => {
    const ref = useRef(null);
    const [order, setOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const whouseid = localStorage.getItem('whousedata');
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = order.slice(firstIndex, lastIndex);
    const npage = Math.ceil(order.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const [filterstate, setFilterState] = useState([]);
    const [filterorder, setFilterOrder] = useState([]);
    const [filterdelivery, setFilterDelivery] = useState([]);
    const [filtervalues, setFilterValue] = useState();
    const [checkvalues, setCheckedValue] = useState([]);
    const [filtervalidated, setFilterValidated] = useState(false);

    useEffect(() => {
        if (whouseid) {
            getAllOrders()
            getFilterStateDeliveryOrder();
        }
        else {
            navigate("/");
        }
    }, [whouseid])

    const getAllOrders = () => {
        setIsloading(true);
        fetchAllDataAPI('warehouse-get-all-orders-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setOrder(...[res.data.orders]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const columns = [
        {
            id: 'order_number',
            displayName: 'Order No.'
        },
        {
            id: 'cust_name',
            displayName: 'Name'
        },
        {
            id: 'cust_phone',
            displayName: 'Mobile'
        },
        {
            id: 'subtotal_price',
            displayName: 'Total Price'
        },
        {
            id: 'created_date',
            displayName: 'Order date'
        },
        {
            id: 'created_time',
            displayName: 'Order Time'
        },
        {
            id: 'state',
            displayName: 'State'
        },
        {
            id: 'city',
            displayName: 'City'
        },
        {
            id: 'pincode',
            displayName: 'Pincode'
        },
        {
            id: 'payment_method',
            displayName: 'Payment Method'
        },
        {
            id: 'vendor_name',
            displayName: 'Plateform Name'
        },
    ];
    const exportOrder = order;
    const getFilterStateDeliveryOrder = () => {
        fetchAllDataAPI('warehouse-get-state-delivery-order-filter-data').then((res) => {
            if (res.data.status === 1) {
                setFilterState(...[res.data.state])
                setFilterOrder(...[res.data.ostatus])
                setFilterDelivery(...[res.data.dstatus])
            }
        })
    }
    const handleOrderFilter = async (event) => {
        setIsloading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const filterdata = {
                    f_order_id: filtervalues.f_order_id,
                    f_order_mobile: filtervalues.f_order_mobile,
                    f_order_awb: filtervalues.f_order_awb,
                    f_order_state: filtervalues.f_order_state,
                    f_order_sdate: filtervalues.f_order_sdate,
                    f_order_edate: filtervalues.f_order_edate,
                    f_order_dstatus: filtervalues.f_order_dstatus,
                    f_order_ostate: filtervalues.f_order_ostate,
                }
                filterByParams('warehouse-get-after-order-filter-data', filterdata).then((res) => {
                    if (res.data.status === 1) {
                        setIsloading(false);
                        setOrder(...[res.data.orders]);
                    }
                    else {
                        setIsloading(false);
                        setOrder("");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setFilterValidated(true);
    };
    const resetOrder = () => {
        getAllOrders();
        ref.current.reset();
    }
    let handleFilterInputs = e => {
        setFilterValue({
            ...filtervalues,
            [e.target.name]: e.target.value,
        });
    };
    const handleCheckbox = (e) => {
        let isSelected = e.target.checked;
        const { name, checked } = e.target;
        let value = parseInt(e.target.value);

        if (name === "allSelect") {
            let temOrder = records.map(record => { return { ...record, isChecked: checked } });
            setOrder(temOrder);
            if (records.length === checkvalues.length) {
                setCheckedValue([]);
            }
            else {
                const postid = records.map((item) => {
                    return item.crm_order_id;
                })
                setCheckedValue(postid);
            }
        }
        else {
            let temOrder = records.map(record => record.crm_order_id === name ? { ...record, isChecked: checked } : record);
            setOrder(temOrder);
            if (isSelected) {
                setCheckedValue([...checkvalues, value]);
            }
            else {
                setCheckedValue((prevData) => {
                    return prevData.filter((id) => {
                        return id != value;
                    })
                })
            }
        }
    };
    console.log("checkvalues", checkvalues.toString())
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::ALL ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="d-flex">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All ORDERS</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter card cardform">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <h5 className="mb-0 text-danger">Order Filter</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <Form ref={ref} noValidate validated={filtervalidated} onSubmit={handleOrderFilter} method="GET">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Order ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By Order ID"
                                                        name="f_order_id"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Mobile Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By Mobile Number"
                                                        name="f_order_mobile"
                                                        onChange={handleFilterInputs}
                                                        pattern="[6789][0-9]{9}"
                                                        maxLength={10}
                                                        minLength={10}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>AWB Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By AWB Number"
                                                        name="f_order_awb"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom10">
                                                    <Form.Label>Search By State</Form.Label>
                                                    <Form.Select
                                                        placeholder="Gender Name"
                                                        name="f_order_state"
                                                        onChange={handleFilterInputs}>
                                                        <option value="">Select State</option>
                                                        {filterstate?.map((filterstate, index) => (
                                                            <option key={index} value={filterstate?.state_name}>{filterstate?.state_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_sdate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_edate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom10">
                                                    <Form.Label>Search By Delivery Status</Form.Label>
                                                    <Form.Select
                                                        name="f_order_dstatus"
                                                        onChange={handleFilterInputs}>
                                                        <option value="">Select Delivery Status</option>
                                                        {filterdelivery?.map((filterdelivery, index) => (
                                                            <option key={index} value={filterdelivery?.dstatus_name}>{filterdelivery?.dstatus_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom10">
                                                    <Form.Label>Search By Order Status</Form.Label>
                                                    <Form.Select
                                                        name="f_order_ostate"
                                                        onChange={handleFilterInputs}>
                                                        <option value="">Select Order Status</option>
                                                        {filterorder?.map((filterorder, index) => (
                                                            <option key={index} value={filterorder?.ostatus_name}>{filterorder?.ostatus_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-2" style={{ marginTop: "36px" }}>
                                                <Form.Group as={Col} md="3">
                                                    <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>
                                                    <span style={{ marginLeft: "10px" }} onClick={resetOrder} className="btn btn-sm btn-secondary"><FiRefreshCcw /> Refresh</span>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <CsvDownloader filename="All Orders"
                                                        extension=".csv"
                                                        datas={exportOrder}
                                                        columns={columns}
                                                    >
                                                        <CiExport className="ofiltersvg" />
                                                        <button className="btn btn-info btn-sm btnorderfilter"> Export</button>
                                                    </CsvDownloader>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table size="sm" className="table-sm" responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="allSelect"
                                                onChange={handleCheckbox}
                                                checked={checkvalues.length === records.length || false}
                                            />Select All
                                        </th>
                                        <th>Order No.</th>
                                        <th>AWB Number</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Total Amount</th>
                                        <th>Adv Amount</th>
                                        <th>Balance Amount</th>
                                        <th>State</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        [...Array(6)].map((_, i) => (
                                            <tr key={i}>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}>
                                                        <Skeleton animation="wave" variant="text" />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) :
                                        records ?
                                            records?.map((record, index) => (
                                                <tr key={record?.crm_order_id}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name={record?.crm_order_id}
                                                            value={record?.crm_order_id}
                                                            checked={record?.isChecked || false}
                                                            onChange={handleCheckbox}
                                                        />
                                                        {index + 1}
                                                    </td>
                                                    <td><Badge bg="success">{record?.order_number}</Badge></td>
                                                    <td>{record?.awb_number}</td>
                                                    <td>{record?.order_name}</td>
                                                    <td>{record?.order_mobile}</td>
                                                    <td>{numberFormat(record?.grand_total)}</td>
                                                    <td>{numberFormat(record?.order_advance_amt)}</td>
                                                    <td>{numberFormat(record?.total_balance)}</td>
                                                    <td>{record?.order_state}</td>
                                                    <td>{moment(record?.created_date).format('Do MMM YYYY')}</td>
                                                    <td>{record?.created_time}</td>
                                                    <td>{record?.shipping_status}</td>
                                                    <td>{record?.order_confirm_status}</td>
                                                    <td><Link target="_blank" to={`/order-invoice/${record.order_number}`} className="badge badge-success"><FaRegEye style={{ fontSize: "20px" }} /></Link></td>
                                                </tr>
                                            ))
                                            :
                                            <tr >
                                                <td className="text-center" colSpan={16}>Data not founds......</td>
                                            </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>AWB Number</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Total Amount</th>
                                        <th>Adv Amount</th>
                                        <th>Balance Amount</th>
                                        <th>State</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default AllOrder