import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { Modal, Table, Button, Col, Form, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FaEye, FaPlus, FaRegHandPointRight, FaTrash } from 'react-icons/fa'
import { activeStatus, addFormData, fetchAllDataByIDAPI, removeDataFromList } from '../utils/api'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { MdAssignmentAdd } from "react-icons/md";
import moment from 'moment'

const Dealer = () => {
    const [isLoading, setIsloading] = useState(false);
    const whouseid = localStorage.getItem('whousedata');
    const [dealer, setDealer] = useState([]);
    const [show, setShow] = useState(false);
    const [dealerid, setDealerId] = useState([]);
    const [assignvalues, setAssignValue] = useState();
    const [validated, setValidated] = useState(false);
    const [product, setProduct] = useState([]);
    const [showdetail, setDetailShow] = useState(false);
    const [pdealer, setPDealer] = useState([]);
    const [phdealer, setPHDealer] = useState([]);

    useEffect(() => {

        getAllDealer(whouseid);
        getAllActiveProducts(whouseid);

    }, [whouseid]);

    const handleClose = () => setShow(false);
    const handleProductAssign = (did) => {
        setShow(true);
        setDealerId((did));
    }
    const handleDetailClose = () => setDetailShow(false);
    const handleProductDetail = (did) => {
        setDetailShow(true);
        fetchAllDataByIDAPI(`warehouse-get-dealer-product-quantity-detail/${did}`).then((res) => {
            if (res.data.status === 1) {
                setPDealer(...[res.data.dealerp]);
                setPHDealer(...[res.data.dealerphistory]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    const getAllDealer = () => {
        setIsloading(true);
        fetchAllDataByIDAPI(`warehouse-get-all-dealer-data/${whouseid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setDealer(...[res.data.dealer]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const handleDealerRemove = (dealerid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('warehouse-remove-dealer-data', dealerid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllDealer(whouseid);
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    const handleDealerActive = (dealerid) => {
        activeStatus('warehouse-active-dealer-status', dealerid).then((res) => {
            if (res.data.status === 1) {
                getAllDealer(whouseid);
            }
        })
    }
    const handleDealerInactive = (dealerid) => {
        activeStatus('warehouse-inactive-dealer-status', dealerid).then((res) => {
            if (res.data.status === 1) {
                getAllDealer(whouseid);
            }
        })
    }
    const getAllActiveProducts = (whouseid) => {
        setIsloading(true);
        fetchAllDataByIDAPI(`warehouse-all-active-product-data/${whouseid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setProduct(...[res.data.product]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const handleDealerProductQuantityForm = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const pqdata = { dealerid: dealerid, wh_id: whouseid, deal_p_id: assignvalues.deal_p_id, dealer_total_qty: assignvalues.dealer_total_qty }
                addFormData('warehouse-add-dealer-product-quantity-data', pqdata).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Quantity has been added successfully!");
                        setShow(false)
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };
    const handleInputs = e => {
        setAssignValue({
            ...assignvalues,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::ALL DEALERS</title>
            </Helmet>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleDealerProductQuantityForm}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Select Product</Form.Label>
                                <Form.Select required
                                    placeholder="Category Name"
                                    name="deal_p_id"
                                    onChange={handleInputs}>
                                    <option value="">Select Product</option>
                                    {product?.map((product, index) => (
                                        <option value={product?.product_id}>{product?.product_name}</option>
                                    ))}

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a product name
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Label>Add Quantity</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Add Quntity"
                                    name="dealer_total_qty"
                                    pattern="[0-9]*"
                                    onChange={handleInputs}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a valid product quantity.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button type="submit" className="btnupdate btn-sm">Add Product Quantity</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showdetail} onHide={handleDetailClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Product Quantity Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Current Qty</th>
                                <th>Total Qty</th>
                                <th>Create Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pdealer?.map((pdealer, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{pdealer?.product_name}</td>
                                    <td><span className="badge badge-success">{pdealer?.dealer_current_qty}</span></td>
                                    <td><span className="badge badge-secondary">{pdealer?.dealer_total_qty}</span></td>
                                    <td>{moment(pdealer?.create_date).format('Do MMMM YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <h5 className="text-success">Product Quantity History</h5>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Total Qty</th>
                                <th>Create Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {phdealer?.map((phdealer, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{phdealer?.product_name}</td>
                                    <td><span className="badge badge-success">{phdealer?.total_qty}</span></td>
                                    <td>{moment(phdealer?.create_date).format('Do MMMM YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Delaers</li>
                                </ol>
                            </nav>
                        </div>
                        <Link to="/add-dealer" className="btn btn-md btn-info"><FaPlus /> Add Dealer</Link>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card tablecard">
                            <div className="table-responsive">
                                <Table size="sm" responsive="lg" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Pincode</th>
                                            <th>Country</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            [...Array(6)].map((_, i) => (
                                                <tr key={i}>
                                                    {Array.from({ length: 10 }).map((_, index) => (
                                                        <td key={index}>
                                                            <Skeleton animation="wave" variant="text" />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))
                                        ) :
                                            dealer?.map((dealer, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{dealer?.dealer_name}</td>
                                                    <td>{dealer?.dealer_email}</td>
                                                    <td>{dealer?.dealer_mobile}</td>
                                                    <td>{dealer?.dealer_city}</td>
                                                    <td>{dealer?.dealer_state}</td>
                                                    <td>{dealer?.dealer_pincode}</td>
                                                    <td>{dealer?.dealer_country}</td>
                                                    <td>
                                                        {(() => {
                                                            if (dealer?.dealer_status === "Active") {
                                                                return (
                                                                    <button className="badge bg-success btnstatus" onClick={() => handleDealerActive(dealer?.dealer_id)}>Active</button>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <button className="badge bg-danger btnstatus" onClick={() => handleDealerInactive(dealer?.dealer_id)}>Inactive</button>
                                                                )
                                                            }
                                                        })()}
                                                    </td>
                                                    <td className="btnsection">
                                                        <button className="btnAssign" onClick={() => handleProductAssign(dealer?.dealer_id)}><MdAssignmentAdd /></button>
                                                        <button className="btnDetail" onClick={() => handleProductDetail(dealer?.dealer_id)}><FaEye /></button>
                                                        <button className="btnDelete" onClick={() => handleDealerRemove(dealer?.dealer_id)}><FaTrash /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Pincode</th>
                                            <th>Country</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </Table>

                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Dealer